@font-face
	font-family 'Roboto'
	font-style normal
	font-weight 400
	src local('Roboto'), local('Roboto-Regular'), url('../fonts/Roboto.woff2') format('woff2')
@font-face
	font-family 'Roboto'
	font-style normal
	font-weight 500
	src local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Robotomedium.woff2') format('woff2')
@font-face
	font-family 'Roboto'
	font-style normal
	font-weight 700
	src local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/Robotobold.woff2') format('woff2')
@font-face
	font-family 'OpenSans'
	font-style normal
	font-weight 400
	src local('Open Sans'), local('OpenSans'), url('../fonts/OpenSans.woff2') format('woff2')
@font-face
	font-family 'OpenSans'
	font-style normal
	font-weight 600
	src local('Open Sans Semibold'), local('OpenSans-Semibold'), url('../fonts/OpenSanssemibold.woff2') format('woff2')

@font-face
	font-family 'LegalDesk'
	font-style normal
	font-weight 400
	src local('LegalDesk'), local('LegalDesk-Regular'), url('../fonts/LegalDesk-Regular.ttf')
@font-face
	font-family 'LegalDesk'
	font-style normal
	font-weight 500
	src local('LegalDesk Medium'), local('LegalDesk-Medium'), url('../fonts/LegalDesk-Medium.ttf')
@font-face
	font-family 'LegalDesk'
	font-style normal
	font-weight 700
	src local('LegalDesk Bold'), local('LegalDesk-Bold'), url('../fonts/LegalDesk-Bold.ttf')

html
body
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif
	font-weight normal
	font-size 12px
	height 100%

body
	min-width 1088px

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
	margin 0
	padding 0
	border 0
	font-size 100%
	font inherit
	vertical-align baseline
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section
	display block

body
	line-height 1

ol, ul
	list-style none
	padding 0

blockquote, q
	quotes none

blockquote:before, blockquote:after,
q:before, q:after
	content ''
	content none

table
	border-collapse collapse
	border-spacing 0

a
	text-decoration none
	cursor pointer

button
input
textarea
checkbox
radio
	outline none

input
	-webkit-appearance none
	-moz-appearance none
	appearance none
	margin 0
	border 0
	border-radius 0


.b-logo
	width 32px
	height 32px
	display inline-block
	vertical-align middle
	cursor pointer
	background url('./../images/icons/logo.svg')
	background-size contain !important

.g-vmiddle
	vertical-align middle
	display inline-block

.b-icon
	vertical-align middle
	display inline-block
	width 20px
	height 20px

	&__facebook
		background url('./../images/icons/facebook.svg')

.b-logo
	position absolute
	top 40px
	left 40px
	width 64px
	height 64px
	display block
	cursor pointer
	background url('./../images/icons/logo_big.svg')

.b-container
	display table
	width 100%
	height 100%

.b-form-container
	display table-row

.b-form-wrapper
	display table-cell
	vertical-align middle

.b-form
	width 384px
	margin 0 auto

.b-form-title
	font-size 22px
	line-height 24px
	margin-bottom 11px
	color #22313f
	font-weight normal

.b-form-text
	font-weight normal
	color #9098a0
	font-size 13px
	line-height 16px
	margin-bottom 24px

.b-button
	margin-top 16px
	display block
	width 100%
	height 48px
	cursor pointer
	font-weight normal
	border 0
	line-height 24px
	background-color #ffc409
	font-size 13px
	letter-spacing .2px
	border-radius 3px
	text-align center
	color #24313e
	box-sizing border-box
	padding 12px 16px 12px

	&__uppercase
		text-transform uppercase
		padding 13px 16px 11px

	&__blue
		background #3b5998
		color #fff

		.b-icon__facebook
			margin -1px 6px 0 0

	&:disabled
		.b-button-text
			opacity .35

:focus
	outline 0

input::-ms-clear
	display none

.b-input-title
	line-height 16px
	color #9098a0
	font-weight normal
	font-size 11px
	position absolute
	left 17px
	right 0
	top 10px

.b-input-field
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif

	&.ng-empty + div
		display none


	&.ng-empty:focus::-webkit-input-placeholder
		color rgba(0,0,0,0)

.b-input
	position relative
	display block
	margin-top -1px

	&.error
		.b-input-field
			background-color #fff5f7!important

			& + div
				color #ed5565

input::-webkit-input-placeholder
textarea::-webkit-input-placeholder
	color #6c7a89
	font-size 13px
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif

input::-moz-placeholder
textarea::-moz-placeholder
	color #6c7a89
	font-size 13px
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif

input:-ms-input-placeholder
textarea:-ms-input-placeholder
	color #6c7a89
	font-size 13px
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif

input:-moz-placeholder
textarea:-moz-placeholder
	color #6c7a89
	font-size 13px
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif

.b-input-field
	width 100%
	line-height 36px
	padding 6px 16px 5px
	font-family LegalDesk, Roboto, Arial, Helvetica, sans-serif
	color #24313e
	font-size 13px
	border 1px solid #dbdfe1
	overflow hidden
	text-overflow ellipsis
	white-space nowrap
	box-sizing border-box
	height 49px

	&:focus
	&:not(.ng-empty)
		line-height 16px
		padding 24px 16px 7px

	&:focus
		background-color #f8f9fa

	&:focus + div
		color #4183d7
		display block

input.b-input-field + div
	color #4183d7

.b-link
	color #4183d7
	cursor pointer
	line-height 24px
	font-weight normal

	&__action
		display inline-block
		padding-top 24px
		font-size 13px

.b-recovery-form
	.b-form-title
		margin-bottom 27px

.b-reset-form
	.b-form-title
		margin-bottom 15px

	.b-form-text
		margin-bottom 28px

.b-without-form
	.b-link
		padding-top 28px

	.b-form-title
		margin-bottom 15px

	.b-form-text
		margin 0

.b-input-field:focus::-webkit-input-placeholder
	color #6c7a89

.b-input-field:focus::-moz-placeholder
	color #6c7a89

.b-input-field:focus:-ms-input-placeholder
	color #6c7a89

.b-input-field:focus:-moz-placeholder
	color #6c7a89

.b-input-field:-webkit-autofill
	box-shadow inset 0 0 0 32px #fff

.b-input.error .b-input-field:-webkit-autofill
	box-shadow inset 0 0 0 32px #fff5f7

.b-input-error
	position absolute
	z-index 6
	bottom 45px
	width 100%
	text-align center

	&.show
		display block

.b-input-error-text
	display inline-block
	color #fff
	background-color #ed5565
	padding 4px 8px
	font-weight normal
	line-height 16px
	font-size 11px
	border-radius 3px

@media all and (max-width: 812px)
	body
		min-width 320px

	.b-form
		min-width 288px
		max-width 384px
		width auto
		padding 0 16px

	.b-container
		height auto
		min-width 320px
		position relative
		margin 0 auto
		padding-top 93px

	.b-logo
		top 16px
		left 16px
		width 48px
		height 48px
		background url('./../images/icons/logo.svg')
